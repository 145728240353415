import { Injectable } from '@angular/core';
import { ApiRequestService } from '@shared/index';
import { BehaviorSubject } from 'rxjs';
import { TimeSlotModel } from '../models/time-slot.model';

@Injectable({
	providedIn: 'root'
})
export class OperationalTimeService {
	slotsList = new BehaviorSubject([]);
	slotDetails = new BehaviorSubject(null);
	constructor(private apiService: ApiRequestService) {
	}

	addSlot(reqData) {
		return this.apiService.post('outlet', reqData);
	}

	editSlot(slotData) {
		this.slotDetails.next(slotData);

	}
}
